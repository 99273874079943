<template>
  <div>
    <CartList></CartList>
  </div>
</template>
<script>
import CartList from "@/components/frontend/Wishlist/CartList.vue";

export default {
  name: "Category",
  components: {
    CartList,
  },
};
</script>
<style lang="scss"></style>
